import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import GenericPage from "../components/generic-page";

function NotFoundPage({ data }) {
  const notFoundPage = data?.siteSettings?.edges[0]?.node?.notFoundPage;

  return (
    <Layout data={data}>
      <SEO title={notFoundPage?.heading ?? "404"} />
      {notFoundPage && <GenericPage data={notFoundPage}></GenericPage>}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($locale: String) {
    ...AllFragment
  }
`;
export default NotFoundPage;
